import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';

class TopBar extends Component {

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<div>
				<div className="ltn__header-top-area section-bg-6">
					<div className="container">
						<div className="row">
							<div className="col-md-6">
								<div className="ltn__top-bar-menu ltn__header-top-area" style={{ fontWeight: 'bold'}}>
									An M/WBE Company

									{/* */}
								</div>
								<div>

								</div>
							</div>

							<div className="col-md-6">
								<div className="top-bar-right text-end">
									<div className="ltn__top-bar-menu">
										<ul>
											<li>

												<div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
													<ul>
														<li>
															<a href="https://billboardinsider.com/vendor-profile-interboro-signs/" target='_blank' >
																<span className="active-currency">Billboard Insider Article
																</span></a>
														</li>
													</ul>
												</div>
											</li>
											<li>

												<div className="ltn__social-media">
													<ul>
														<li><a href={publicUrl + "assets/media/FLEET_OF_EQUIPMENT.pdf"} target='_blank' >
															<span className="active-currency">
																<span >Fleet of Equipment </span>
															</span></a></li>

													</ul>
												</div>
											</li>
											<li>

												<div className="ltn__social-media">
													<ul>
														<li><a href={publicUrl + "assets/media/marketing_sheet.pdf"} target='_blank' >
															<span className="active-currency">
																<span style={{ fontStyle: 'italic' }}>INTERBORO </span>Overview
															</span></a></li>

													</ul>
												</div>
											</li>
											
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default TopBar